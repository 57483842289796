<template>
<div class=" wrapper" v-loading.fullscreen.lock="loading" :element-loading-text="$t('connecting')" element-loading-spinner="el-icon-loading" element-loading-background="rgba(0, 0, 0, 0.8)">
    <div class="wp-body bgf pr">
     <div class="bg1">
            <img width="100%" src="../../assets/img/building.svg" alt="AA">
        </div>
        <div class="w-full pr" style="padding:10px 10px;">
       
            <h1 class="about-title"> {{$t('about')}} </h1>
            <p class="text-p">
                {{$t('system_about')}}
            </p>

            <p class="title-p">{{$t('system_task')}}:</p>
            <table class="ul-table">
                <tr>
                    <td class="number-td">1.</td>
                    <td class="text-td"> {{$t('system_task1')}}</td>
                </tr>
                <tr>
                    <td class="number-td">2.</td>
                    <td class="text-td">{{$t('system_task2')}}</td>
                </tr>
                 <tr>
                    <td class="number-td">3.</td>
                    <td class="text-td"> {{$t('system_task3')}} </td>
                </tr>
                 <tr>
                    <td class="number-td">4.</td>
                    <td class="text-td">{{$t('system_task4')}}</td>
                </tr>
                 <tr>
                    <td class="number-td">5.</td>
                    <td class="text-td">{{$t('system_task5')}}</td>
                </tr>
            </table>

            <p class="title-p">{{$t('system_the_goal')}}:</p>
            <table class="ul-table">
                <tr>
                    <td class="number-td">1.</td>
                    <td class="text-td">{{$t('system_the_goal1')}}</td>
                </tr>
                <tr>
                    <td class="number-td">2.</td>
                    <td class="text-td">{{$t('system_the_goal2')}}</td>
                </tr>
                 <tr>
                    <td class="number-td">3.</td>
                    <td class="text-td">{{$t('system_the_goal3')}}  </td>
                </tr>
                 <tr>
                    <td class="number-td">4.</td>
                    <td class="text-td">{{$t('system_the_goal4')}} </td>
                </tr>
                 <tr>
                    <td class="number-td">5.</td>
                    <td class="text-td">{{$t('system_the_goal5')}}</td>
                </tr>
            </table>
        </div>
    </div>

</div>
</template>

<script>
export default {
    data() {
        return {
            activeName: 'first'
        };
    },
    methods: {
       
    }
};
</script>

<style lang="scss">
.about-title {
    font-weight: 600;
    margin-bottom: 20px;
    font-size: 20px;
}

.title-p {
    font-weight: 600;
    font-size: 16px;
    margin: 20px 0px 10px 0px;
}
.bgf{
    background: #fff;
}
.pr{
    position: relative;
}
.bg1{
    position: absolute;
    opacity: 0.2;
    bottom: -60px;
    right: -150px;
    width: 80%;
    
}
.about-ul {
    list-style-type: decimal;
    padding: 15px;
}

.about-ul::marker {
    font-weight: 600 !important;
    color: red;
}
.number-td{
    padding: 10px 10px 10px 0px;
    font-size: 16px;
    font-weight: 600;
    vertical-align: top;
    padding-left: 20px;

}
.ul-table{
    width: 100%;
    
}
.text-td{
    padding: 10px;
    vertical-align: top;
    font-size: 16px;

}
.text-p{
    font-size: 16px;
}
</style>
